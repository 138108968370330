<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          Tracking Categories
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <sdFeatherIcons type="calendar" size="16" />&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-button class="btn-signin" size="small">
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    :data-source="record.record.linedata"
                    :columns="linecolumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-button class="btn-signin" size="small">
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="ignoreTableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    :data-source="record.record.linedata"
                    :columns="linecolumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
export default defineComponent({
  name: "xero_lateentry",
  components: {
    Main,
    CardToolbox,
    TopToolBox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const openurl = (id) => {
      window.open(
        "https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=" + id,
        "_blank"
      );
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroVat.data = [];

      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const getdata = () => {
      loading.value = true;
      // dispatch("getvatData", "none").then(
      //   (response) => {
      //     if (response.status == 200) {
      //       state.XeroVat.data = response.data;
      //       loading.value = false;
      //     } else {
      //       state.XeroVat.data = [];
      //       loading.value = false;
      //       //onCancel();
      //     }
      //   },
      //   (error) => {
      //     state.XeroVat.data = [];
      //     loading.value = false;
      //   }
      // );
      state.XeroVat.data = [];
      loading.value = false;
    };

    const getignoredata = () => {
      loading.value = true;
      // dispatch("xerovatIgnore", "none").then(
      //   (response) => {
      //     if (response.status == 200) {
      //       state.XeroVat.data = response.data;
      //       loading.value = false;
      //     } else {
      //       state.XeroVat.data = [];
      //       loading.value = false;
      //       //onCancel();
      //     }
      //   },
      //   (error) => {
      //     state.XeroVat.data = [];
      //     loading.value = false;
      //   }
      // );
      state.XeroVat.data = [];
      loading.value = false;
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreVat", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });

            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("getvatData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroVat.data = response.data;
              loading.value = false;
            } else {
              state.XeroVat.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroVat.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("xerovatIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroVat.data = response.data;
              loading.value = false;
            } else {
              state.XeroVat.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroVat.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreVatBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const TableColumns = [
      {
        title: "Contact Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    const linecolumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
        fixed: "left",
      },
      {
        title: "Transaction Type",
        dataIndex: "transaction_type",
        key: "transaction_type",
        sorter: (a, b) => a.transaction_type.length - b.transaction_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Invoice No ",
        dataIndex: "invoice_no",
        key: "invoice_no",
        width: "20%",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Description",
        dataIndex: "desc",
        key: "desc",
        width: "20%",
        sorter: (a, b) => a.desc.length - b.desc.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "VAT Code",
        dataIndex: "vat_code",
        key: "vat_code",
        sorter: (a, b) => a.vat_code.length - b.vat_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Vat Name",
        dataIndex: "vat_code",
        key: "vat_code",
        sorter: (a, b) => a.vat_code.length - b.vat_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Gross Amount ",
        dataIndex: "gross_amount",
        key: "gross_amount",
        sorter: (a, b) => a.gross_amount.length - b.gross_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "VAT Amt",
        dataIndex: "vat_amount",
        key: "vat_amount",
        sorter: (a, b) => a.vat_amount.length - b.vat_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Net Amount",
        dataIndex: "net_amount",
        key: "net_amount",
        sorter: (a, b) => a.net_amount.length - b.net_amount.length,
        sortDirections: ["descend", "ascend"],
      },
    ];

    var TableData = computed(() =>
      state.XeroVat.data
        /*.sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact) => {
          const {
            id,
            name,
            invoice_no,
            tax_amount,
            reference,
            currency_code,
            status,
            due_date,
            children,
          } = contact;
          var linedata = [];
          for (let i = 0; i < children.length; i++) {
            linedata.push({
              key: id,
              date: children[i].due_date,
              transaction_type: children[i].record_type,
              invoice_no: (
                <div class="table-actions">
                  <>
                    <p style="width: 100px;overflow:hidden;text-align: left;">
                      {" "}
                      {children[i].invoice_id}
                    </p>
                  </>
                </div>
              ),
              desc: (
                <div class="table-actions">
                  <>
                    <p style="width: 100px;overflow:hidden;text-align: left;">
                      {" "}
                      {children[i].description}
                    </p>
                  </>
                </div>
              ),
              gross_amount: children[i].unit_amount,
              vat_amount: children[i].tax_amount,
              net_amount: children[i].line_amount,
              vat_code: children[i].tax_type,
            });
          }
          return {
            key: id,
            name: name,
            /* invoice_no: (
              <a onClick={() => openurl(invoice_id)} to="#">
                {invoice_no}
              </a>
            ),*/
            reference: reference,
            currency_code: currency_code,
            status: status,
            due_date: due_date,
            linedata: linedata,
            action: (
              <div class="table-actions">
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(id, "B")}
                  >
                    <a-button
                      class="btn-icon"
                      type="default"
                      to="#"
                      shape="circle"
                    >
                      <sdFeatherIcons type="trash-2" size="16" />
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
          };
        })
    );

    var ignoreTableData = computed(() =>
      state.XeroVat.data
        /* .sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact) => {
          const {
            id,
            contact_id,
            name,
            invoice_no,
            invoice_id,
            reference,
            currency_code,
            status,
            due_date,
            children,
          } = contact;
          var linedata = [];
          for (let i = 0; i < children.length; i++) {
            linedata.push({
              key: id,
              date: children[i].due_date,
              transaction_type: children[i].record_type,
              invoice_no: children[i].invoice_id,
              desc: (
                <div class="table-actions">
                  <>
                    <p style="width: 200px;overflow:hidden;text-align: left;">
                      {" "}
                      {children[i].description}
                    </p>
                  </>
                </div>
              ),
              gross_amount:children[i].unit_amount, 
              vat_amount: children[i].tax_amount,
              net_amount: children[i].line_amount,
              vat_code: children[i].tax_type,
            });
          }
          return {
            key: id,
            name: name,
            invoice_no: (
              <a onClick={() => openurl(invoice_id)} to="#">
                {invoice_no}
              </a>
            ),
            reference: reference,
            currency_code: currency_code,
            status: status,
            due_date: due_date,
            linedata: linedata,
            action: (
              <div class="table-actions">
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(id, "A")}
                  >
                    <a-button
                      class="btn-icon"
                      type="default"
                      to="#"
                      shape="circle"
                    >
                      <sdFeatherIcons type="trash-2" size="16" />
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
          };
        })
    );
    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      linecolumns,
      DateSelect,
      orgdata,
    };
  },
});
</script>
