<template>
  <div>
    <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 0px">
            Interest Booked Under HP Account
          </sdHeading>
           <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync: 
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
        </div>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
               <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <sdFeatherIcons type="calendar" size="16" />&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col></a-row
        >
      </template>
      <template v-slot:buttons>
        <a-row :gutter="25" style="justify-content: center">
          <a-col>
            <ProjectSorting>
              <div class="project-sort-bar">
                <div class="project-sort-group">
                  <div class="sort-group">
                    <a-select @change="onSorting" defaultValue="Active">
                      <a-select-option value="Active">Active</a-select-option>
                      <a-select-option value="Ignored"
                        >Dismissed</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
          </a-col></a-row
        >
      </template>
    </sdPageHeader>
  </div>
</template>

<script>
import { Main, TableWrapper } from "../../../../styled";
import {
  TopToolBox,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent, computed, ref, onMounted } from "vue";
import { dispatch, useStore } from "vuex";
import { message, Notification } from "ant-design-vue";

export default defineComponent({
  name: "xero_interestbooked",
  components: {
    Main,
    TableWrapper,
    ProjectSorting,
    TopToolBox,
    UserTableStyleWrapper,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);

    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroContact.data = [];
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };
    const openurl = (id) => {
      window.open("https://go.xero.com/Contacts/View/" + id, "_blank");
      // location.href = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + id;
    };
    const getdata = () => {
      loading.value = true;
      dispatch("getcontactData").then(
        (response) => {
          if (response.status == 200) {
            state.XeroContact.data = response.data;
            loading.value = false;
          } else {
            loading.value = false;
            // onCancel();
          }
        },
        (error) => {
          state.XeroContact.data = [];
        }
      );
    };
    const getignoredata = () => {
      loading.value = true;
      dispatch("xerocontactIgnore").then(
        (response) => {
          if (response.status == 200) {
            state.XeroContact.data = response.data;
            loading.value = false;
          } else {
            loading.value = false;
            state.XeroContact.data = [];
            // onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.XeroContact.data = [];
        }
      );
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreContact", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };
    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreContactBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const TableColumns = [
      {
        title: "Contact Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Transactions",
        dataIndex: "transaction",
        key: "transaction",
        sorter: (a, b) => a.transaction.length - b.transaction.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Match Name",
        dataIndex: "match_name",
        key: "match_name",
        sorter: (a, b) => a.match_name.length - b.match_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Match Email",
        dataIndex: "match_email",
        key: "match_email",
        sorter: (a, b) => a.match_email.length - b.match_email.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Transactions",
        dataIndex: "match_transaction",
        key: "match_transaction",
        sorter: (a, b) =>
          a.match_transaction.length - b.match_transaction.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Match param",
        dataIndex: "match_param",
        key: "match_param",
        sorter: (a, b) => a.match_param.length - b.match_param.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    let TableData = computed(() =>
      state.XeroContact.data.map((contact) => {
        const {
          id,
          name,
          email,
          rec_id,
          contact_id,
          transaction,
          match_id,
          match_name,
          match_param,
          match_email,
          match_transaction,
        } = contact;
        return {
          key: rec_id,
          name: (
            <a onClick={() => openurl(contact_id)} to="#">
              {name}
            </a>
          ),
          email: email,
          match_param: match_param,
          match_email: match_email,
          transaction: transaction,
          match_name: (
            <a onClick={() => openurl(match_id)} to="#">
              {match_name}
            </a>
          ),
          match_transaction: match_transaction,
          action: (
            <div class="table-actions">
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(rec_id, "B")}
                >
                  <a-button
                    class="btn-icon"
                    type="default"
                    to="#"
                    shape="circle"
                  >
                    <sdFeatherIcons type="trash-2" size="16" />
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );
    var ignoreTableData = computed(() =>
      state.XeroContact.data.map((contact) => {
        const {
          id,
          name,
          email,
          match_email,
          rec_id,
          contact_id,
          transaction,
          match_id,
          match_name,
          match_param,
          match_transaction,
        } = contact;
        return {
          key: rec_id,
          name: (
            <a onClick={() => openurl(contact_id)} to="#">
              {name}
            </a>
          ),
          email: email,
          match_param: match_param,
          match_email: match_email,
          transaction: transaction,
          match_name: (
            <a onClick={() => openurl(match_id)} to="#">
              {match_name}
            </a>
          ),
          match_transaction: match_transaction,
          action: (
            <div class="table-actions">
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(rec_id, "A")}
                >
                  <a-button
                    class="btn-icon"
                    type="default"
                    to="#"
                    shape="circle"
                  >
                    <sdFeatherIcons type="trash-2" size="16" />
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );
    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,orgdata
    };
  },
});
</script>
