<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          Repeating Sales Invoice
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <sdFeatherIcons type="calendar" size="16" />&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <Description text="Provide a List of all Sales Invoices Repeating in the Report Review Period." />
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin /></div
    ></sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              />
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :dataSource="ignoreTableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              />
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template> 
<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import Description from "./../Description.vue";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default defineComponent({ 
  name: "repeat_sinvoice",
  components: {
    Main,
    BasicFormWrapper,
    Description,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.xerorepatInvoice.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
        if(search_match == false){search_match = (((data.date)).includes(substring));
        }if(search_match == false){search_match = (((data.due_date)).includes(substring));
        }if(search_match == false){search_match = ((String(data.total)).includes(substring));
        }
        if(search_match == false){
          (state.xerorepatInvoice.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      state.xerorepatInvoice.data = xero_get_datas;
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.xerorepatInvoice.data = [];
      localStorage.setItem("search_data",JSON.stringify([]));
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    }; 
    const openurl = (id,invoice_type) => { 
      let url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
      switch (invoice_type) {
        case 'ACCPAY':
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCREC':
          url = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCRECCREDIT':
          url = 'https://go.xero.com/AccountsReceivable/EditCreditNote.aspx?creditNoteID=';
          break;
        case 'ACCPAYCREDIT':
          url = 'https://go.xero.com/AccountsPayable/EditCreditNote.aspx?creditNoteID=';
          break;
        default:
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
      }
      window.open(
          url + id,
          "_blank"
        );
    };
    const getdata = () => { 
        loading.value = true;
        dispatch("getrepeatsales").then(
          (response) => {
            if (response.status == 200) {
              state.xerorepatInvoice.data = response.data;
              localStorage.setItem("search_data",JSON.stringify(response.data));
              loading.value = false;
            } else {
              loading.value = false; 
            }
          },
          (error) => {
            state.xerorepatInvoice.data = [];
            loading.value = false; 
          }
        );
    };
    const getignoredata = () => {
      loading.value = true;
        dispatch("getrepeatsalesIgnore").then(
          (response) => {
            if (response.status == 200) {
              state.xerorepatInvoice.data = response.data;
              localStorage.setItem("search_data",JSON.stringify(response.data));
              loading.value = false;
            } else {
              loading.value = false;
              state.xerorepatInvoice.data = [];
            }
          },
          (error) => {
            loading.value = false;
            state.xerorepatInvoice.data = [];
          }
        );
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
          selectedRowKeys.value = selectedRowKeys;
          selectedRows.value = selectedRows;
          if (selectedRowKeys.value.length == 0) {
            checkselected.value = false;
          } else {
            checkselected.value = true;
            selectedid = selectedRowKeys.value;
            selectcount.value = selectedRowKeys.value.length + " Rows Selected";
          }
        },
        getCheckboxProps: (record) => (
          {
            disabled: record.name === "Disabled User",
            name: record.name,
          },
          console.log(record)
        ),
    };
    const bulkIgnore = (option) => {
      var data = {};
        data.selectedid = selectedid;
        data.option = option;
        dispatch("ignorerepeatsalesBulk", data).then(
          (response) => {
            if (response.status == 200) {
              Notification["success"]({
                message: "Success!",
                description: "Updated.",
              });
              if (selectedoption.value != "Active") {
                getignoredata();
              } else {
                getdata();
              }
            } else {
              Notification["error"]({
                message: "Error!",
                description: "Error .",
              }); 
            }
            checkselected.value = false;
            selectcount.value = null;
            selectedid.value = null;
          },
          (error) => {
            checkselected.value = false;
            selectcount.value = null;
            selectedid.value = null;
          }
        );
    };
    const onHandleignore = (id, status) => {
       var data = {};
        data.id = id;
        data.status = status;
        dispatch("ignorerepeatsales", data).then(
          (response) => {
            if (response.status == 200) {
              Notification["success"]({
                message: "Success!",
                description: "Updated.",
              });
              if (selectedoption.value != "Active") {
                getignoredata();
              } else {
                getdata();
              }
            } else {
              Notification["error"]({
                message: "Error!",
                description: "Error .",
              }); 
            }
            checkselected.value = false;
            selectcount.value = null;
            selectedid.value = null;
          },
          (error) => {
            checkselected.value = false;
            selectcount.value = null;
            selectedid.value = null;
          }
        );
    };
    const TableColumns = [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: (a, b) => a.date.length - b.date.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Customer Name",
          dataIndex: "customer_name",
          key: "customer_name",
          sorter: (a, b) => a.customer_name.length - b.customer_name.length,
          sortDirections: ["descend", "ascend"],
        },
        /*{
          title: "Reference",
          dataIndex: "reference",
          key: "reference",
          sorter: (a, b) => a.reference.length - b.reference.length,
          sortDirections: ["descend", "ascend"],
        },*/
        {
          title: "Invoice Amt",
          dataIndex: "invoice_amt",
          key: "invoice_amt",
          sorter: (a, b) => a.invoice_amt.length - b.invoice_amt.length,
          sortDirections: ["descend", "ascend"],
        },
        /*{
          title: "Repeats",
          dataIndex: "repeats",
          key: "repeats",
          sorter: (a, b) => a.repeats.length - b.repeats.length,
          sortDirections: ["descend", "ascend"],
        },*/
        {
          title: "Next Invoice Date",
          dataIndex: "end_date",
          key: "end_date",
          sorter: (a, b) =>
            a.end_date.length - b.end_date.length,
          sortDirections: ["descend", "ascend"],
        }, 
        {
          title: "link",
          dataIndex: "link",
          key: "link",
          sorter: (a, b) => a.link.length - b.link.length,
          sortDirections: ["descend", "ascend"],
        }, 
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          width: "90px",
        },
      ];

     let TableData = computed(() =>
        state.xerorepatInvoice.data
        .map((contact) => {
            const {
              id,
              RepeatingInvoiceID,invoice_id,invoice_type,
              start_date, 
              contact_name,
              reference,
              interval,
              total, 
              due_date,
              link,
            } = contact;
            return {
              key: id, 
              date: start_date.split(' ')[0],
              customer_name: contact_name,
              reference: reference,
              invoice_amt: total, 
              repeats: interval,
              end_date: due_date.split(' ')[0],
              link: (
              <div class="">
                <>
                  <a onClick={() => openurl(invoice_id,invoice_type)} to="#">
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
              action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(id, "B")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Dismiss
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
            };
          })
      );
    
    var ignoreTableData = computed(() =>
      state.xerorepatInvoice.data.map((contact) => {
            const {
              id,
              RepeatingInvoiceID,invoice_id,
              start_date, 
              contact_name,
              reference,
              interval,
              total, 
              due_date,
              link,
              invoice_type,
            } = contact;
            return {
              key: id, 
              date: start_date.split(' ')[0],
              customer_name: contact_name,
              reference: reference,
              invoice_amt: total, 
              repeats: interval,
              end_date: due_date.split(' ')[0],
              link: (
              <div class="">
                <>
                  <a onClick={() => openurl(invoice_id,invoice_type)} to="#">
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
              action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(id, "A")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Active
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
            };
          })
      );

    const exportToCSV = () => {
      const inputData = state.xerorepatInvoice.data.map((contact) => {
       const {
              id,
              RepeatingInvoiceID,
              start_date, 
              contact_name,
              reference,
              interval,
              total, 
              due_date,
              invoice_no,
              link,
            } = contact;
        return {
          id: id, 
          'Invoice No': invoice_no,
              date: start_date.split(' ')[0],
              customer_name: contact_name,
              reference: reference,
              invoice_amt: total, 
              repeats: interval,
              'Next Invoice Date': due_date.split(' ')[0],
        };
      });
       var linedata1 = [];
      linedata1.push({
      Point : "Repeating Sales Invoice",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " Repeating Sales Invoice " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
       loading,
        TableColumns,
        TableData,
        rowSelection,
        selectcount,
        ignoreTableData,
        checkselected,
        selectedoption,
        addFormState,
        handleSearch,
        bulkIgnore,
        onSorting,orgdata,exportToCSV
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style>
