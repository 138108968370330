<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
         Transaction with Director Name
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <sdFeatherIcons type="calendar" size="16" />&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>


            </sdHeading>

          </div>
        </a-col></a-row
      >
      <BasicFormWrapper>
            <a-form
              name="contact"
              :rules="rules"
              :model="addFormState"
              @submit="handleOk"
              :layout="addFormState.layout"
            >
            <a-row :gutter="30">
            <a-col>
              <a-form-item name="f_name">
                <a-input
                  v-model:value="addFormState.f_name"
                  placeholder="Input First Name"
                required/>
              </a-form-item>
              </a-col>
              <a-col>
              <a-form-item name="l_name">
                <a-input
                  v-model:value="addFormState.l_name"
                  placeholder="Input Last Name"
                required/>
              </a-form-item>
              </a-col>
              <a-col>
                <a-button
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
                Submit
              </a-button>
              </a-col>
              </a-row>
            </a-form>
          </BasicFormWrapper>
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <Description text="Report for Spend Money, Purchase Invoices, etc. according to selected Director name(Bank Transactions & Invoices Captured)." />
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                    name="contact"
                    :rules="rules"
                    :model="addFormState"
                    :layout="addFormState.layout"
                    style="
                      margin-top: 20px;
                      margin-right: 10px;
                    "
                  >
                    <a-row  >
                      <a-col>
                        <a-form-item name="search_text">
                          <a-input
                            v-model:value="addFormState.search_text"
                            placeholder="Search Here...."
                            @change="handleSearch"
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                   <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :headers="table_headers"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    class="ant-table-striped"
                    :data-source="record.record.linedata"
                    :columns="lineColumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                    name="contact"
                    :rules="rules"
                    :model="addFormState"
                    :layout="addFormState.layout"
                    style="
                      margin-top: 20px;
                      margin-right: 10px;
                    "
                  >
                    <a-row  >
                      <a-col>
                        <a-form-item name="search_text">
                          <a-input
                            v-model:value="addFormState.search_text"
                            placeholder="Search Here...."
                            @change="handleSearch"
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                  <a-button class="btn-signin" size="small"  @click="exportToCSV()">
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :data-source="ignoreTableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    :data-source="record.record.linedata"
                    :columns="lineColumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox,BasicFormWrapper, TableWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";
export default defineComponent({
  name: "xero_vatjournal",
  components: {
    Main,
    Description,
    CardToolbox,
    BasicFormWrapper,
    TopToolBox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    

    onMounted(() => {
      getdata();
      });
    
    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.XeroDainvoices.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
        if(search_match == false && data.invoice_no){search_match = (((data.invoice_no).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.date_string)).includes(substring));
        }if(search_match == false){search_match = ((String(data.total)).includes(substring));
        }if(search_match == false){search_match = ((String(data.count)).includes(substring));
        }
        if(search_match == false){
          (state.XeroDainvoices.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index]);
        }
      });
      state.XeroDainvoices.data = xero_get_datas;
    };

    const openurl = (id,type) => {
      let url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
      switch (type) {
        case 'ACCPAY':
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCREC':
          url = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCRECCREDIT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'ACCPAYCREDIT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'RECEIVE-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;  
        case 'RECEIVE':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;  
        case 'MJS':
          url = 'https://go.xero.com/Journal/View.aspx?invoiceID=';
          break;    
        default:
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
      }
      window.open(
          url + id,
          "_blank"
        ); 
      
    };
    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroDainvoices.data = [];
      localStorage.setItem("search_data",JSON.stringify([]));
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const getdata = (values) => { 
     //alert(addFormState.f_name);
     localStorage.setItem("f_name", addFormState.f_name);
      loading.value = true;
      dispatch("getDaData",addFormState.f_name).then(
        (response) => {
          // console.log(response);
         
          if (response.status == 200) {
            state.XeroDainvoices.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroDainvoices.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroDainvoices.data = [];
          loading.value = false;
        }
      );
      //  state.XeroDainvoices.data = [];
      //  loading.value = false;
    };

    const getignoredata = () => {
      
      loading.value = true;
      dispatch("xeroDaIgnore").then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            state.XeroDainvoices.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroDainvoices.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroDainvoices.data = [];
          loading.value = false;
        }
      );
      // state.XeroDainvoices.data = [];
      // loading.value = false;
    };
  const addFormState = reactive({
      search_text: "",
      f_name: "",
      l_name: "",
      layout: "vertical",
    });
    const rules = {
      f_name: [
        {
          required: true,
          message: "Please input value",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleOk = (values) => {
      // console.log(addFormState);
      //alert(addFormState.f_name);
      var name= addFormState.f_name +" "+ addFormState.l_name;
      //alert(name);
      localStorage.setItem("f_name", name);
      dispatch("getDaData",name).then(
        (response) => {
          // console.log(response);
         
          if (response.status == 200) {
            state.XeroDainvoices.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroDainvoices.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroDainvoices.data = [];
          loading.value = false;
        }
      );
      //  state.XeroDainvoices.data = [];
      //  loading.value = false;
    };
    const onHandleignore = (id, status) => {
      
      var data = {};
      data.id = id;
      data.status = status;
      console.log(data);
      dispatch("ignoreDa", data).then(
        (response) => {
          // console.log(response);
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            }); 
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("getDaData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroDainvoices.data = response.data;
              loading.value = false;
            } else {
              state.XeroDainvoices.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroDainvoices.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("xeroDaIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroDainvoices.data = response.data;
              loading.value = false;
            } else {
              state.XeroDainvoices.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroDainvoices.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreDaBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };


    // Table Columns
    const TableColumns = [
    {
        title: "Invoice No ",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Client Name",
        dataIndex: "contact_name",
        key: "contact_name",
        sorter: (a, b) => a.contact_name.length - b.contact_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Reference",
        dataIndex: "reference",
        key: "reference",
        sorter: (a, b) => a.reference.length - b.reference.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => a.total.length - b.total.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Transactions",
        dataIndex: "count",
        key: "count",
        sorter: (a, b) => a.count.length - b.count.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];
    const lineColumns = [
      
      {
        title: "Description",
        dataIndex: "desc",
        key: "desc",
        width: "20%",
        sorter: (a, b) => a.desc.length - b.desc.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Account code",
        dataIndex: "acc_code",
        key: "acc_code",
        sorter: (a, b) => a.acc_code.length - b.acc_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Account Name",
        dataIndex: "account_name",
        key: "account_name",
        sorter: (a, b) => a.account_name.length - b.account_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Vat Name",
        dataIndex: "vat_name",
        key: "vat_name",
        sorter: (a, b) => a.vat_name.length - b.vat_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Gross Amount ",
        dataIndex: "gross_amount",
        key: "gross_amount",
        sorter: (a, b) => a.gross_amount.length - b.gross_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        width: "90px",
      },
    ];
    
    var TableData = computed(() =>
      state.XeroDainvoices.data.map((contact) => {
        const {
          id,
          contact_name,
          invoice_no,
          count,
          invoice_id,
          reference,
          currency_code,
          status,
          date,
          date_string,
          total,
          type,
          children,
          account_id,
          transaction_id,
        } = contact;
        var linedata = [];
        for (let i = 0; i < children.length; i++) {
          linedata.push({
            key: id,
            date: children[i].date,
            transaction_type: children[i].record_type,
            invoice_no: (
              <div class="table-actions">
                <>
                  <p style="width: 100px;overflow:hidden;text-align: left;">
                    {children[i].invoice_no}
                  </p>
                </>
              </div>
            ),
            desc: (
              <div class="table-actions">
                <>
                  <p style="width: 100px;overflow:hidden;text-align: left;">
                    {" "}
                    {children[i].description}
                  </p>
                </>
              </div>
            ),
            link: (
              <div class="table-actions">
                <>
                  <a
                    onClick={() =>
                      openurl(invoice_id, type)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
            gross_amount: children[i].unit_amount,
            vat_amount: children[i].tax_amount,
            net_amount: children[i].line_amount,
            acc_code: children[i].account_code,
            account_name: children[i].account_name,
            tax_type: children[i].tax_type,
            vat_name: children[i].vat_name,
          });
        }
        return {
          key: transaction_id,
          date: date_string,
          total:total,
          contact_name: (
            <div>
              {contact_name}
              
            </div>
          ),
          invoice_no:invoice_no ,
          invoice_id:invoice_id,
          reference: reference,
          currency_code: currency_code,
          status: status,
          count: <p>{count + " Transactions"}</p>,
          linedata: linedata,
          action: (
            <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(transaction_id, "B")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="primary"
                      to="#"
                      id="dismiss_btn"
                    >
                      Dismiss
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
          ),
        };
      })
    );

     var ignoreTableData = computed(() =>
      state.XeroDainvoices.data.map((contact) => {
          const {
          id,
          contact_name,
          invoice_no,
          count,
          invoice_id,
          reference,
          currency_code,
          status,
          date,
          date_string,
          total,
          type,
          children,
          transaction_id,
          account_id,
        } = contact;
        var linedata = [];
        for (let i = 0; i < children.length; i++) {
          linedata.push({
            key: id,
            date: children[i].date,
            transaction_type: children[i].record_type,
            invoice_no: (
              <div class="table-actions">
                <>
                  <p style="width: 100px;overflow:hidden;text-align: left;">
                    {" "}
                    {children[i].invoice_no}
                  </p>
                </>
              </div>
            ),
            desc: (
              <div class="table-actions">
                <>
                  <p style="width: 100px;overflow:hidden;text-align: left;">
                    {" "}
                    {children[i].description}
                  </p>
                </>
              </div>
            ),
            link: (
              <div class="table-actions">
                <>
                  <a
                    onClick={() =>
                      openurl(invoice_id, type)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
            gross_amount: children[i].unit_amount,
            vat_amount: children[i].tax_amount,
            net_amount: children[i].line_amount,
            acc_code: children[i].account_code,
            account_name: children[i].account_name,
            tax_type: children[i].tax_type,
            vat_name: children[i].vat_name,
          });
        }
        return {
          key: transaction_id,
          date:date_string,
          total:total,
          contact_name: (
            <div>
              {contact_name}
              <br />
              
            </div>
          ),
          invoice_no: invoice_no ,
          reference: reference,
          currency_code: currency_code,
          status: status,
          count: <p>{count + " Transactions"}</p>,
          linedata: linedata,
          action: (
            <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(transaction_id, "A")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="primary"
                      to="#"
                      id="dismiss_btn"
                    >
                      Dismiss
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
          ),
        };
        })
    );

    const exportToCSV = () => {
      const inputData = state.XeroDainvoices.data.map((contact) => {
        var linedata = [];
        const {
          id,
          count,
          contact_name,
          invoice_no,
          reference,
          currency_code,
          status,
          date,
          children,
        } = contact;
        for (let i = 0; i < children.length; i++) {
          linedata.push({
            id: id,
            date: children[i].due_date,
            transaction_type: children[i].record_type,
            invoice_no: children[i].invoice_no,
            desc: children[i].description,
            gross_amount:  children[i].unit_amount,
            vat_amount: children[i].tax_amount,
            net_amount: children[i].line_amount,
            acc_code: children[i].account_code,
            account_name: children[i].account_name,
            tax_type: children[i].tax_type,
            vat_name: children[i].vat_name,
          });
        }
        return {
          id: id,
          contact_name: contact_name,
          date: date,
          transactions: count,
          data: linedata,
          invoice_no: invoice_no,
          reference: reference,
          currency_code: currency_code,
          status: status,
          
        };
      });

      var linedata = [];
      var linedata1 = [];
      linedata1.push({
      Point : "Transaction with Director Name",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      for (let i = 0; i < inputData.length; i++) {
        linedata.push({
          Id: inputData[i]["id"],
          Date: inputData[i]["date"],
          "Contact Name": inputData[i]["contact_name"],
          "Invoice_No": inputData[i]["invoice_no"],
          Transactions: inputData[i]["transactions"],
        });
        for (let j = 0; j < inputData[i]["data"].length; j++) {
          linedata.push({
            line_Id: inputData[i]["data"][j]["id"],
            Date: inputData[i]["data"][j]["date"],
            "Transaction Type": inputData[i]["data"][j]["transaction_type"],
            "Account Code": inputData[i]["data"][j]["acc_code"],
            "Account Name": inputData[i]["data"][j]["account_name"],
            "Vat Code": inputData[i]["data"][j]["tax_type"],
            "Vat Name": inputData[i]["data"][j]["vat_name"],
            Description: inputData[i]["data"][j]["desc"],
            "Gross Amount": inputData[i]["data"][j]["gross_amount"],
            "Vat Amount": inputData[i]["data"][j]["vat_amount"],
            "Net Amount": inputData[i]["data"][j]["net_amount"],
            
          });
        }
      }

      const fileName =
        orgdata.value.client_name +
        " Transaction with Director Name " +
        orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,linedata,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
   
    return {
      loading,
      TableColumns,
      lineColumns,
      TableData,
      ignoreTableData,
      rowSelection,
      selectcount,
      addFormState,
      checkselected,
      selectedoption,
      bulkIgnore,
      handleOk,
      onSorting,
      DateSelect,
      orgdata,
      handleSearch,
      exportToCSV
    };
  },
});
</script>
 <style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style> 