<template>
  <div>
    <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 0px">
            Duplicate Contacts
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync:
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
        </div>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
              <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <sdFeatherIcons type="calendar" size="16" />&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col></a-row
        >
      </template>
      <template v-slot:buttons>
        <ProjectSorting>
          <div class="project-sort-bar">
            <div class="project-sort-group">
              <div class="sort-group">
                <a-select
                  @change="onSorting"
                  defaultValue="Active"
                  class="border"
                >
                  <a-select-option value="Active">Active</a-select-option>
                  <a-select-option value="Ignored">Dismissed</a-select-option>
                </a-select>
              </div>
            </div>
          </div>
        </ProjectSorting>
      </template>
    </sdPageHeader>
    <Main>
      <Description text="Contacts with Similar Name and Email." />
      <sdCards headless v-if="loading">
        <div class="spin">
          <a-spin /></div
      ></sdCards>
      <sdCards headless v-else>
        <template v-if="selectedoption == 'Active'">
          <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <a-button
                      class="btn-signin"
                      size="small"
                      @click="exportToCSV()"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                    <!-- <vue-excel-xlsx
                      :data="TableData"
                      :columns="excelColumns"
                      :file-name="'filename'"
                      :file-type="'xlsx'"
                      :sheet-name="'sheetname'"
                    >
                      Download
                    </vue-excel-xlsx> -->
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="TableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                >
                </a-table>
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('A')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                        Active</span
                      >
                    </a-button>
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <a-button
                      class="btn-signin"
                      size="small"
                      @click="exportToCSV()"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="ignoreTableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: ignoreTableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                />
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import { Main, TableWrapper,BasicFormWrapper } from "../../../../styled";
import {
  TopToolBox,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent, computed, ref, onMounted,reactive } from "vue";
import { dispatch, useStore } from "vuex";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";

export default defineComponent({
  name: "dup_contact",
  components: {
    Main,
    Description,
    BasicFormWrapper,
    TableWrapper,
    ProjectSorting,
    TopToolBox,
    UserTableStyleWrapper,
  },

  setup() {
    var loading = ref(true);
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

    onMounted(() => {
      getdata();
    });

    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.XeroContact.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      // console.log(substring);
      // console.log(JSON.parse(localStorage.getItem("search_data")));
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.match_param).toLowerCase()).includes(substring.toLowerCase()));
        if(search_match == false){search_match = (((data.match_name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){
          if(data.transaction){
            search_match = (((data.transaction)).includes(substring));
          }
          if(data.match_transaction){
            search_match = (((data.match_transaction)).includes(substring));
          }
        }
        if(search_match == false){
          (state.XeroContact.data).splice(index, 1);
        }else{
          // console.log(index);
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      // console.log(xero_get_datas);
      state.XeroContact.data = xero_get_datas;
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroContact.data = [];
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };
    const openurl = (id) => {
      window.open("https://go.xero.com/Contacts/View/" + id, "_blank");
      // location.href = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + id;
    };
    const getdata = () => {
      loading.value = true;
      dispatch("getcontactData").then(
        (response) => {
          if (response.status == 200) {
            state.XeroContact.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            loading.value = false;
            // onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.XeroContact.data = [];
        }
      );
    };
    const getignoredata = () => {
      loading.value = true;
      dispatch("xerocontactIgnore").then(
        (response) => {
          if (response.status == 200) {
            state.XeroContact.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            loading.value = false;
            state.XeroContact.data = [];
            // onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.XeroContact.data = [];
        }
      );
    };

    const exportToCSV = () => {
      const inputData = state.XeroContact.data.map((contact) => {
        const {
          name,
          email,
          rec_id,
          contact_id,
          match_id,
          transaction,
          match_name,
          match_param,
          match_email,
          match_transaction,
        } = contact;
        return {
          Name: name,
          Email: email,
          Contact_Id: contact_id,
          Transaction: transaction,
          "Match Parameter": match_param,
          "Match Name": match_name,
          "Match Email": match_email,
          "Match Id": match_id,
          "Match Transaction": match_transaction,
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "Duplicate Contacts",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName = orgdata.value.client_name + " Contact Data ";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;

      dispatch("ignoreContact", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        name: record.name,
      }),
    };
    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreContactBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const TableColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },

      {
        title: "Transactions",
        dataIndex: "transaction",
        key: "transaction",
        sorter: (a, b) => a.transaction.length - b.transaction.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Name",
        dataIndex: "match_name",
        key: "match_name",
        sorter: (a, b) => a.match_name.length - b.match_name.length,
        sortDirections: ["descend", "ascend"],
       
      },
      {
        title: "Transactions",
        dataIndex: "match_transaction",
        key: "match_transaction",
        sorter: (a, b) => a.match_transaction.length - b.match_transaction.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Match param",
        dataIndex: "match_param",
        key: "match_param",
        sorter: (a, b) => a.match_param.length - b.match_param.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    let TableData = computed(() =>
      state.XeroContact.data.map((contact) => {
        const {
          id,
          name,
          email,
          rec_id,
          contact_id,
          transaction,
          match_id,
          match_name,
          match_param,
          match_email,
          match_transaction,
        } = contact;
        return {
          key: rec_id,
          name: (
            <div>
              <a onClick={() => openurl(contact_id)} to="#">
                {name}
              </a>
              <span>{email == "None" ? "" : email}</span>
            </div>
          ),
          match_param: match_param,
          transaction: transaction,
          match_name: (
            <div>
              <a onClick={() => openurl(match_id)} to="#">
                {match_name}
              </a>
              <span>{match_email == "None" ? "" : match_email}</span>
            </div>
          ),
          match_transaction: match_transaction,
          action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(rec_id, "B")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Dismiss
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );

    var ignoreTableData = computed(() =>
      state.XeroContact.data.map((contact) => {
        const {
          id,
          name,
          email,
          match_email,
          rec_id,
          contact_id,
          transaction,
          match_id,
          match_name,
          match_param,
          match_transaction,
        } = contact;
        return {
          key: rec_id,
          name: (
            <div>
              <a onClick={() => openurl(contact_id)} to="#">
                {name}
              </a>
              <span>{email == "None" ? "" : email}</span>
            </div>
          ),
          match_param: match_param,
          transaction: transaction,
          match_name: (
            <div>
              <a onClick={() => openurl(contact_id)} to="#">
                {match_name}
              </a>
              <span>{match_email == "None" ? "" : match_email}</span>
            </div>
          ),
          match_transaction: match_transaction,
          action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(rec_id, "A")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Active
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );
    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      exportToCSV,
      addFormState,
      handleSearch, 
      orgdata,
    };
  },
});
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style>
