<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          Journal Schedule
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
         Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <sdFeatherIcons type="calendar" size="16" />&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select
                @change="onSorting"
                defaultValue="Active"
                class="border"
              >
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <Description text="It also captures probable repeating manual journals which are posted repeatedly in report review period and not posted automatically." />
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                   <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                    name="contact"
                    :rules="rules"
                    :model="addFormState"
                    :layout="addFormState.layout"
                    style="
                      margin-top: 20px;
                      margin-right: 10px;
                    "
                  >
                    <a-row  >
                      <a-col>
                        <a-form-item name="search_text">
                          <a-input
                            v-model:value="addFormState.search_text"
                            placeholder="Search Here...."
                            @change="handleSearch"
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                  <a-button class="btn-signin" size="small"  @click="exportToCSV()">
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="ignoreTableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
               
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";
export default defineComponent({
  name: "xero_vatjournal",
  components: {
    Main,
    Description,
    CardToolbox,
    BasicFormWrapper,
    TopToolBox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    

    onMounted(() => {
      getdata();
  
    });

    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.XeroJournal.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.narration).toLowerCase()).includes(substring.toLowerCase()));
        if(search_match == false){ search_match = (((data.account_name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){ search_match = (((data.description).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.date)).includes(substring));
        }if(search_match == false){search_match = (((data.account_code)).includes(substring));
        }if(search_match == false){search_match = (((data.line_amount)).includes(substring));
        }
        if(search_match == false){
          (state.XeroJournal.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      state.XeroJournal.data = xero_get_datas;
    };

    const openurl = (id) => {
      window.open(
        "https://go.xero.com/Journal/View.aspx?invoiceID=" + id,
        "_blank"
      );
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroJournal.data = [];

      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const getdata = () => { 
      loading.value = true;
      dispatch("getjournalData").then(
        (response) => {
          // console.log(response);
         
          if (response.status == 200) {
            state.XeroJournal.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroJournal.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroJournal.data = [];
          loading.value = false;
        }
      );
      //  state.XeroJournal.data = [];
      //  loading.value = false;
    };

    const getignoredata = () => {
      
      loading.value = true;
      dispatch("getjournalDataIgnore").then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            state.XeroJournal.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroJournal.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroJournal.data = [];
          loading.value = false;
        }
      );
      // state.XeroJournal.data = [];
      // loading.value = false;
    };

    const onHandleignore = (id, status) => {
      
      var data = {};
      data.id = id;
      data.status = status;
      // console.log(data);
      dispatch("ignoreJournal", data).then(
        (response) => {
          // console.log(response);
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            }); 
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("getjournalData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroJournal.data = response.data;
              loading.value = false;
            } else {
              state.XeroJournal.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroJournal.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("getjournalDataIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroJournal.data = response.data;
              loading.value = false;
            } else {
              state.XeroJournal.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroJournal.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreJournalBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };


    // Table Columns
     const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Narration",
        dataIndex: "narration",
        key: "narration",
        sorter: (a, b) => a.manual_id.length - b.manual_id.length,
        sortDirections: ["descend", "ascend"],
      },
     
      {
        title: "Account",
        dataIndex: "account_code",
        key: "account_code",
        sorter: (a, b) => a.account_code.length - b.account_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Account Name",
        dataIndex: "account_name",
        key: "account_code",
        sorter: (a, b) => a.account_name.length - b.account_name.length,
        sortDirections: ["descend", "ascend"],
      },
       {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description.length - b.description.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "line_amount",
        key: "line_amount",
        sorter: (a, b) => a.line_amount.length - b.line_amount.length,
        sortDirections: ["descend", "ascend"],
      },
     
      // {
      //   title: "Debit",
      //   dataIndex: "debit",
      //   key: "debit",
      //   sorter: (a, b) => a.debit.length - b.debit.length,
      //   sortDirections: ["descend", "ascend"],
      // },
      // {
      //   title: "Credit",
      //   dataIndex: "credit",
      //   key: "credit",
      //   sorter: (a, b) => a.credit.length - b.credit.length,
      //   sortDirections: ["descend", "ascend"],
      // },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];
    
    let TableData = computed(() =>
      state.XeroJournal.data.map((contact) => {
        const {
          id,
          date,
          narration,
          manual_journal_id,
          account_code,
          account_name,
          description,
          line_amount,
          debit,
          credit,
          link,
        } = contact;
        return {
          key: id,
          date: date,
          narration: narration,
          manual_journal_id: manual_journal_id,
          account_code: account_code,
          account_name:account_name,
          description:description,
          line_amount:line_amount,
          debit: debit,
          credit: credit,
          link: (
              <div class="">
                <>
                  <a
                    onClick={() =>
                      
                      openurl(manual_journal_id)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "B")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Dismiss
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );


     var ignoreTableData = computed(() =>
      state.XeroJournal.data.map((contact) => {
        const {
          id,
          date,
          narration,
          manual_journal_id,
          account_code,
          account_name,
          description,
          line_amount,
          debit,
          credit,
          link,
        } = contact;
        return {
         key: id,
          date: date,
          narration: narration,
          manual_journal_id: manual_journal_id,
          account_code: account_code,
          account_name:account_name,
          description:description,
          line_amount:line_amount,
          debit: debit,
          credit: credit,
          link: (
              <div class="">
                <>
                  <a
                    onClick={() =>
                      openurl(manual_journal_id)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "A")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Active
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );


     const exportToCSV = () => {
      const inputData = state.XeroJournal.data.map((contact) => {
        const {
          id,
          date,
          manual_journal_id, 
          account_code,
          account_name,
          description,
          line_amount,
          debit,
          credit,
        } = contact;

        return {
          key: id,
          date: date,
          manual_journal_id: manual_journal_id,
          account_code: account_code,
          account_name:account_name,
          description:description,
          line_amount:line_amount,
         
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "Repeating Jounral Schedule",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " Repeating Jounral Schedule " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
   
    return {
      loading,
      TableColumns,
      TableData,
      ignoreTableData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      DateSelect,
      orgdata,
      addFormState,
      handleSearch,
      exportToCSV
    };
  },
});
</script>
 <style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style> 
