<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          Late Entries
        </sdHeading>
         <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync: 
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
      </div>
       <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
               <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <sdFeatherIcons type="calendar" size="16" />&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col></a-row
        >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
   <Main>
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                   <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-button class="btn-signin" size="small"  @click="exportToCSV()">
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="ignoreTableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
               
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox, ProjectSorting
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: "xero_lateentry",
  components: {
    Main,
    CardToolbox,
    TopToolBox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const openurl = (id) => {
      window.open(
        "https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=" + id,
        "_blank"
      );
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroLateEntries.data = [];

      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

  const getdata = () => { 
      loading.value = true;
      dispatch("get_lateentries_action").then(
        (response) => {
          // console.log(response);
         
          if (response.status == 200) {
            state.XeroLateEntries.data = response.data;
            loading.value = false;
          } else {
            state.XeroLateEntries.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroLateEntries.data = [];
          loading.value = false;
        }
      );
      //  state.XeroLateEntries.data = [];
      //  loading.value = false;
    };


   const getignoredata = () => {
      
      loading.value = true;
      dispatch("getdata_lateenries_ignore_action").then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            state.XeroLateEntries.data = response.data;
            loading.value = false;
          } else {
            state.XeroLateEntries.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroLateEntries.data = [];
          loading.value = false;
        }
      );
      // state.XeroLateEntries.data = [];
      // loading.value = false;
    };

      const onHandleignore = (id, status) => {
      
      var data = {};
      data.id = id;
      data.status = status;
      // console.log(data);
      dispatch("ignore_late_entries_action", data).then(
        (response) => {
          // console.log(response);
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });

            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("get_lateentries_action", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroLateEntries.data = response.data;
              loading.value = false;
            } else {
              state.XeroLateEntries.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroLateEntries.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("ignore_late_entries_action", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroLateEntries.data = response.data;
              loading.value = false;
            } else {
              state.XeroLateEntries.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroLateEntries.data = [];
            loading.value = false;
          }
        );
      }
    };

      const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignore_lateentries_bulk_action", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Name",
        dataIndex: "contact_name",
        key: "contact_name",
        sorter: (a, b) => a.contact_name.length - b.contact_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: " Transaction Type",
        dataIndex: "transaction_type",
        key: "transaction_type",
        sorter: (a, b) => a.transaction_type.length - b.transaction_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Ref No.",
        dataIndex: "ref_no",
        key: "ref_no",
        sorter: (a, b) => a.ref_no.length - b.ref_no.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Entry Date",
        dataIndex: "entry_date",
        key: "entry_date",
        sorter: (a, b) => a.entry_date.length - b.entry_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Amt",
        dataIndex: "amt",
        key: "amt",
        sorter: (a, b) => a.amt.length - b.amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];


    var TableData = computed(() =>
      state.XeroLateEntries.data
        /*.sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact) => {
          const {
            id,
            name,
            invoice_no,
            tax_amount,
            reference,
            currency_code,
            status,
            due_date,
            link,
          } = contact;
        
          return {
            key: id,
            name: name,
            invoice_no,
            tax_amount: tax_amount,
            reference: reference,
            currency_code: currency_code,
            status: status,
            due_date: due_date,
             link: (
              <div class="table-actions">
                <>
                  <a
                    onClick={() =>
                      // openurl(invoice_id,  invoice_type)
                      openurl(id)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "B")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Dismiss
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
          };
        })
    );

    var ignoreTableData = computed(() =>
      state.XeroLateEntries.data.map((contact) => {
        const {
            id,
            date,
            contact_name,
            transaction_type,
            ref_no,
            entry_date,
            amt,
            link,
        } = contact;
        return {
            key: id,
            name: contact_name,
             transaction_type: transaction_type,
             ref_no:  ref_no,
            entry_date: entry_date,
            amt: amt,
            
          link: (
              <div class="table-actions">
                <>
                  <a
                    onClick={() =>
                      // openurl(invoice_id,  invoice_type)
                      openurl(id)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "A")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Active
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );

     const exportToCSV = () => {
      const inputData = state.XeroLateEntries.data.map((contact) => {
        const {
             id,
            date,
            contact_name,
            transaction_type,
            ref_no,
            entry_date,
            amt,
        } = contact;

        return {
             key: id,
            name: contact_name,
             transaction_type: transaction_type,
             ref_no:  ref_no,
            entry_date: entry_date,
            amt: amt
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "Late Entries",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " Late Entries " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
     loading,
      TableColumns,
      TableData,
      ignoreTableData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      DateSelect,
      orgdata,
      exportToCSV
    };
  },
});
</script>
