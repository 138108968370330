<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          Variance Analysis Pivot Table
        </sdHeading>
      <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync: 
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
      </div>
       <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
               <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <sdFeatherIcons type="calendar" size="16" />&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col></a-row
        >
    </template>
    <template v-slot:buttons>
      <a-row :gutter="25" style="justify-content: center;  ">
        <a-col>
          <ProjectSorting>
            <div class="project-sort-bar">
              <div class="project-sort-group">
                <div class="sort-group">
                  <a-select @change="onSorting" defaultValue="Active">
                    <a-select-option value="Active">Active</a-select-option>
                    <a-select-option value="Ignored">Dismissed</a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
          </ProjectSorting>
        </a-col></a-row
      >
    </template>
  </sdPageHeader>
  <Main>
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin /></div
    ></sdCards>
    <sdCards headless v-else> 
      <template v-if="selectedoption == 'Active'">
          <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-button class="btn-signin" size="small"  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    :data-source="record.record.linedata"
                    :columns="linecolumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
         <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('A')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                        Active</span
                      >
                    </a-button>
                    <a-button class="btn-signin" size="small" >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div> 
              </TopToolBox>
            </a-col>
          </a-row> 
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="ignoreTableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    :data-source="record.record.linedata"
                    :columns="linecolumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
export default defineComponent({
  name: "xero_pivotanalysis",
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });
    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroVat.data = [];
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const openurl = (id) => {
      window.open("https://go.xero.com/Contacts/View/" + id, "_blank");
      // location.href = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + id;
    };
    const getdata = () => {
      loading.value = true;
      // dispatch("getSIDraftData").then(
      //   (response) => {
      //     if (response.status == 200) {
      //       state.XeroVat.data = response.data;
      //       loading.value = false;
      //     } else {
      //       loading.value = false;
      //       //onCancel();
      //     }
      //   },
      //   (error) => {
      //     state.XeroVat.data = [];
      //   }
      // );
      state.XeroVat.data = [];
      loading.value = false;
    };

    const getignoredata = () => {
      loading.value = true;
      // dispatch("getSIDraftIgnore").then(
      //   (response) => {
      //     if (response.status == 200) {
      //       state.XeroVat.data = response.data;
      //       loading.value = false;
      //     } else {
      //       loading.value = false;
      //       state.XeroVat.data = [];
      //       //onCancel();
      //     }
      //   },
      //   (error) => {
      //     loading.value = false;
      //     state.XeroVat.data = [];
      //   }
      // );
      loading.value = false;
          state.XeroVat.data = [];
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreSIDraft", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreSIDraftBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });

            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Nominal Code",
        dataIndex: "nominal_code",
        key: "nominal_code",
        sorter: (a, b) => a.nominal_code.length - b.nominal_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.length - b.type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Supplier",
        dataIndex: "supplier",
        key: "supplier",
        sorter: (a, b) => a.supplier.length - b.supplier.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Net Amt (A)",
        dataIndex: "net_amt_a",
        key: "net_amt_a",
        sorter: (a, b) => a.net_amt_a.length - b.net_amt_a.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Net Amt (B)",
        dataIndex: "net_amt_b",
        key: "net_amt_b",
        sorter: (a, b) => a.net_amt_b.length - b.net_amt_b.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },  
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    const linecolumns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Tracking Id",
        dataIndex: "tracking_id",
        key: "tracking_id",
        sorter: (a, b) => a.tracking_id.length - b.tracking_id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        sorter: (a, b) => a.quantity.length - b.quantity.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Unit Amount",
        dataIndex: "unit_amount",
        key: "unit_amount",
        sorter: (a, b) => a.unit_amount.length - b.unit_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Account Code",
        dataIndex: "account_code",
        key: "account_code",
        sorter: (a, b) => a.account_code.length - b.account_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "20%",
        sorter: (a, b) => a.description.length - b.description.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Tax Amount",
        dataIndex: "tax_amount",
        key: "tax_amount",
        sorter: (a, b) => a.tax_amount.length - b.tax_amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Tax Type",
        dataIndex: "tax_type",
        key: "tax_type",
        sorter: (a, b) => a.tax_type.length - b.tax_type.length,
        sortDirections: ["descend", "ascend"],
      },
    ];

    var TableData = computed(() =>
      state.XeroVat.data
        /*.sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact) => {
          const {
            id,
            name,
            invoice_no,
            invoice_id,
            due_date,
            date,
            reference,
            subtotal,
            totaltax,
            total,
            currency_code,
            status,
            overview_status,
            children,
          } = contact;

          var linedata = [];
          for (let i = 0; i < children.length; i++) {
            linedata.push({
              key: id,
              id: children[i].id,
              line_amount: children[i].line_amount,
              quantity: children[i].quantity,
              unit_amount: children[i].unit_amount,
              account_code: children[i].account_code,
              description: (
                <div class="table-actions">
                  <>
                    <p style="width: 200px;overflow:hidden;text-align: left;">
                      {" "}
                      {children[i].description}
                    </p>
                  </>
                </div>
              ),
              tax_amount: children[i].tax_amount,
              tax_type: children[i].tax_type,
              tracking_id: children[i].tracking_id,
            });
          }

          return {
            key: id,
            name: name,
            invoice_no: (
              <a onClick={() => openurl(invoice_id)} to="#">
                {invoice_no}
              </a>
            ),
            reference: reference,
            status: status,
            date: date,
            due_date: due_date,
            subtotal: subtotal,
            totaltax: totaltax,
            total: total,
            linedata: linedata,
            action: (
              <div class="table-actions">
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(id, "B")}
                  >
                    <a-button
                      class="btn-icon"
                      type="default"
                      to="#"
                      shape="circle"
                    >
                      <sdFeatherIcons type="trash-2" size="16" />
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
          };
        })
    );

    var ignoreTableData = computed(() =>
      state.XeroVat.data
        /*.sort((a, b) => {
          return b.time - a.time;
        })*/
        .map((contact) => {
          const {
            id,
            name,
            invoice_no,
            invoice_id,
            due_date,
            date,
            reference,
            subtotal,
            totaltax,
            total,
            currency_code,
            status,
            overview_status,
            children,
          } = contact;

          var linedata = [];
          for (let i = 0; i < children.length; i++) {
            linedata.push({
              key: id,
              id: children[i].id,
              line_amount: children[i].line_amount,
              quantity: children[i].quantity,
              unit_amount: children[i].unit_amount,
              account_code: children[i].account_code,
              description: (
                <div class="table-actions">
                  <>
                    <p style="width: 200px;   overflow-wrap: break-word; overflow:hidden">
                      {" "}
                      {children[i].description}
                    </p>
                  </>
                </div>
              ),
              tax_amount: children[i].tax_amount,
              tax_type: children[i].tax_type,
              tracking_id: children[i].tracking_id,
            });
          }

          return {
            key: id,
            name: name,
            invoice_no: (
              <a onClick={() => openurl(invoice_id)} to="#">
                {invoice_no}
              </a>
            ),
            reference: reference,
            status: status,
            date: date,
            due_date: due_date,
            subtotal: subtotal,
            totaltax: totaltax,
            total: total,
            linedata: linedata,
            action: (
              <div class="table-actions">
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(id, "B")}
                  >
                    <a-button
                      class="btn-icon"
                      type="default"
                      to="#"
                      shape="circle"
                    >
                      <sdFeatherIcons type="trash-2" size="16" />
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
          };
        })
    );

    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      linecolumns,orgdata
    };
  },
});
</script>
